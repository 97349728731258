import { StyledFlexColBoxContainer } from '../../../../../components/Common/Common.styled';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import List from './List';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import {
  DistrictWideComparisonsStylesCSS,
} from '../../../../AdminAnalytics/DistrictWideComparisons/DistrictWideComparisons.styled';
import useAdminBenchmarkSlideoutHook from './admin-bm-slideout-hook';
import { AdminBenchmarkGradeComparisonResult, AdminBenchmarkScoresDataPoint } from '../../../../../generated/graphql';

interface Props {
  slideOutData: AdminBenchmarkGradeComparisonResult | undefined,
  tab: any,
  toggleDrawer: (event: React.MouseEvent) => void,
  schoolID?: string,
}


const SlideoutScores = ({ slideOutData, tab, toggleDrawer, schoolID }: Props) => {
  const theme = useTheme();
  const styles = DistrictWideComparisonsStylesCSS(theme);
  const slideOutId = slideOutData?.id;
  const slideOutName = slideOutData?.name;

  const {
    loading, totalCount, slideoutData,
    pagination, queryInput,
    handlePageChange, handleSort,
  } = useAdminBenchmarkSlideoutHook({ id: slideOutId, slideOut: tab.slideOutLabel, tabName: tab.value, schoolID });

  const closeFlyover = (event: React.MouseEvent) => {
    pagination.unsetPage();
    toggleDrawer(event);
  };

  return (
    <StyledFlexColBoxContainer role="presentation">
      <Box sx={styles.drawerHeader}>
        <Grid container>
          <Grid item xs={11}>
            <Box>
              <Box>
                <Typography
                  variant='font22'
                  sx={{ color: theme.palette.customWhite.main }}
                >
                  {tab.value}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant='font16'
                >
                  {slideOutName}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={1} container justifyContent="flex-end">
            <IconButton
              aria-label="close grade specific drawer" component="button" size="large" edge="end"
              sx={{ ...styles.drawerHeaderIcon, paddingRight: 2 }}
              onClick={closeFlyover}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <List
          loading={loading!}
          pagination={pagination}
          limit={queryInput.limit!}
          sort={queryInput.sort! }
          order={queryInput.orderBy!}
          handlePageChange={handlePageChange}
          handleSort={handleSort}
          totalCount={totalCount}
          slideOutName={tab.slideOutLabel}
          dataList={slideoutData}
        />
      </Box>
    </StyledFlexColBoxContainer>);
};

export default SlideoutScores;
