import { PaginationProps } from '../../../../../variables/types';
import {
  AdminBenchmarkReportingWidgetSortType, AdminBenchmarkScoresDataPoint,
  OrderBy,
} from '../../../../../generated/graphql';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import {
  DistrictWideComparisonsStylesCSS,
} from '../../../../AdminAnalytics/DistrictWideComparisons/DistrictWideComparisons.styled';
import { Box, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { StyledTableCell3, StyledTableCell5 } from '../../../../Admin/admin-tables-styled';
import ArrowDownIcon from '../../../../../components/Icons/ArrowDownIcon';
import NoResults from '../../../../../components/NoResults';
import TablePagination from '../../../../../components/TablePagination';
import { TeacherBenchmarkDashBoard } from '../../../../../variables/constant';
import EmptyBenchmarkResults from '../../../../../components/Icons/EmptyBenchmarksResults';
import SlideOutRow from './SlideOutRow';

interface Props {
  isTableEmpty?: boolean,
  pagination: PaginationProps,
  loading: boolean,
  totalCount: number,
  limit: number,
  sort: AdminBenchmarkReportingWidgetSortType,
  order: OrderBy,
  dataList: AdminBenchmarkScoresDataPoint[],
  handlePageChange: (_: React.ChangeEvent, value: React.SetStateAction<number>) => void | undefined,
  handleSort: ((column: AdminBenchmarkReportingWidgetSortType) => void) | Function,
  slideOutName: string,
}

const List = ({
  isTableEmpty = false,
  pagination,
  loading,
  limit,
  sort,
  order,
  totalCount,
  dataList,
  handlePageChange,
  handleSort,
  slideOutName,
}: Props) => {
  const theme = useTheme();
  const styles = DistrictWideComparisonsStylesCSS(theme);
  const orderByIcon = order === OrderBy.Desc ? 'asc' : 'desc';

  const getSortType = (tab: string) => {
    switch (tab) {
      case 'schools':
        return AdminBenchmarkReportingWidgetSortType.SchoolName;
      case 'teachers':
        return AdminBenchmarkReportingWidgetSortType.TeacherName;
      case 'grades': return AdminBenchmarkReportingWidgetSortType.GradePosition;
      default: return '';
    }
  };

  const sortColumn = (title: string, sortEnum: AdminBenchmarkReportingWidgetSortType) => (
    <TableSortLabel
      direction={orderByIcon}
      onClick={handleSort(sortEnum)}
      active={sort === sortEnum}
      IconComponent={ArrowDownIcon}
    >
      {title}
    </TableSortLabel>
  );

  return (
    <Box
      display="grid"
      width="100%"
      height="100%"
    >
      <Box>
        <Table
          stickyHeader
          aria-label={slideOutName + '-admin-slideout-scores-table'}
        >
          <TableHead>
            <TableRow >
              <TableCell sx={{ border: 'none' }}>
              </TableCell>
              <TableCell colSpan={6} sx={{ textAlign: 'center', border: 'none', height: '52px' }}>
                                Speaking
              </TableCell>
              <TableCell colSpan={6} sx={{ textAlign: 'center', border: 'none', height: '52px' }}>
                                Writing
              </TableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell5 sx={{ ...styles.width35, ...styles.drawerTableColumn, textAlign:'left' }}>
                {sortColumn(slideOutName,  getSortType(slideOutName.toLowerCase()) as AdminBenchmarkReportingWidgetSortType)}
              </StyledTableCell5>
              <StyledTableCell3 sx={{ ...styles.drawerTableColumn, textAlign: 'left', ...(sort !== AdminBenchmarkReportingWidgetSortType.SpBoyScore && { padding: '8px 8px 8px 24px' }) }}>
                {sortColumn('BOY', AdminBenchmarkReportingWidgetSortType.SpBoyScore)}
              </StyledTableCell3>
              <StyledTableCell3 sx={{ ...styles.drawerTableColumn }}>
              </StyledTableCell3>
              <StyledTableCell3 sx={{ ...styles.drawerTableColumn, textAlign: 'left', ...(sort !== AdminBenchmarkReportingWidgetSortType.SpMoyScore && { padding: '8px 8px 8px 24px' }) }} >
                {sortColumn('MOY', AdminBenchmarkReportingWidgetSortType.SpMoyScore)}
              </StyledTableCell3>
              <StyledTableCell3 sx={{ ...styles.drawerTableColumn }}>
              </StyledTableCell3 >
              <StyledTableCell3 sx={{ ...styles.drawerTableColumn, textAlign: 'left', ...(sort !== AdminBenchmarkReportingWidgetSortType.SpEoyScore && { padding: '8px 8px 8px 24px' }) }} >
                {sortColumn('EOY', AdminBenchmarkReportingWidgetSortType.SpEoyScore)}
              </StyledTableCell3>
              <StyledTableCell3 sx={{ ...styles.drawerTableColumn, ...(sort !== AdminBenchmarkReportingWidgetSortType.SpYtdChange && { padding: '8px 8px 8px 24px' }) }}>
                {sortColumn('YTD Change', AdminBenchmarkReportingWidgetSortType.SpYtdChange)}
              </StyledTableCell3>
              <StyledTableCell3 sx={{ ...styles.drawerTableColumn, textAlign: 'left', ...(sort !== AdminBenchmarkReportingWidgetSortType.WrBoyScore && { padding: '8px 8px 8px 24px' }) }} >
                {sortColumn('BOY', AdminBenchmarkReportingWidgetSortType.WrBoyScore)}
              </StyledTableCell3>
              <StyledTableCell3 sx={{ ...styles.drawerTableColumn }}>
              </StyledTableCell3>
              <StyledTableCell3 sx={{ ...styles.drawerTableColumn, textAlign: 'left', ...(sort !== AdminBenchmarkReportingWidgetSortType.WrMoyScore && { padding: '8px 8px 8px 24px' }) }}  >
                {sortColumn('MOY', AdminBenchmarkReportingWidgetSortType.WrMoyScore)}
              </StyledTableCell3>
              <StyledTableCell3 sx={{ ...styles.drawerTableColumn }}>
              </StyledTableCell3>
              <StyledTableCell3 sx={{ ...styles.drawerTableColumn, textAlign: 'left', ...(sort !== AdminBenchmarkReportingWidgetSortType.WrEoyScore && { padding: '8px 8px 8px 24px' }) }} >
                {sortColumn('EOY', AdminBenchmarkReportingWidgetSortType.WrEoyScore)}
              </StyledTableCell3>
              <StyledTableCell3 sx={{ ...styles.drawerTableColumn, ...(sort !== AdminBenchmarkReportingWidgetSortType.WrYtdChange && { padding: '8px 8px 8px 24px' }) }} >
                {sortColumn('YTD Change', AdminBenchmarkReportingWidgetSortType.WrYtdChange)}
              </StyledTableCell3>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading && dataList?.map((score) => (
              <SlideOutRow progressScore={score} />
            ))}
            {isTableEmpty && !loading && (
              <TableRow>
                <TableCell
                  colSpan={14}
                  align="center"
                >
                  <NoResults
                    description={TeacherBenchmarkDashBoard.ProgressScore.insufficientData.description}
                    svgComponent={<EmptyBenchmarkResults />}
                  />
                </TableCell>
              </TableRow>
            )}
            {loading && (
              <TableRow>
                <TableCell
                  colSpan={14}
                  align="center"
                >
                  <CircularProgress size={28} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        paddingTop="20px"
      >
        {totalCount > limit && (<TablePagination
          count={Math.ceil(totalCount / limit) || 0}
          page={pagination.page}
          onChange={handlePageChange}
          defaultPage={pagination.page}
          disabled={totalCount < limit}
        />)}
      </Box>
    </Box>
  );
};
export default List;
