import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  AdminBenchmarkReportingWidgetSortType,
  OrderBy,
  useAddTypeformUserMappingMutation,
  useAdminGradeBenchmarkComparisonQuery,
  useAdminSchoolBenchmarkComparisonQuery,
  useAdminTeacherBenchmarkComparisonQuery,
  useGetAdminDashboardSurveyTypeformPhase2Query,
  useGetLastSnowflakeUpdateTimeQuery,
  useMyDistrictQuery,
  useMySchoolQuery,
} from '../../../../../generated/graphql';
import useRole from '../../../../../hooks/useRole';
import { ADMIN_BENCHMARK_COMPARISON_TABS, ROWS_PER_PAGE } from '../../../../../variables/constant';
import RoutingPaths from '../../../../RoutingPath';
import usePagination from '../../../../../utils/usePagination';


const useAdminBenchmarkProgressHook = () => {
  const { isDistrictAdmin, isSchoolAdmin } = useRole();
  const history = useHistory();
  const pagination = usePagination();
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const [ dismissTypeform, setDismissTypeform] = useState<boolean>(false);
  const [showSubmissionCount, setShowSubmissionCount] = useState<boolean>(true);
  const [comparisonTabs, setComparisonTabs] = useState(isDistrictAdmin ? ADMIN_BENCHMARK_COMPARISON_TABS.districtAdmin : ADMIN_BENCHMARK_COMPARISON_TABS.schoolAdmin);
  const [showFlyover, setShowFlyover] = useState(false);
  const toggleFlyover = () => {
    setShowFlyover(!showFlyover);
  };
  const [flyoverData, setFlyoverData] = useState();
  const openFlyOver = (data: any) => {
    setFlyoverData({
      ...data,
    });
  };

  useEffect(() => {
    if (!!flyoverData) toggleFlyover();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flyoverData]);

  const initialQueryInput = {
    limit: rowsPerPage,
    page: pagination.page,
    sort: isDistrictAdmin ? AdminBenchmarkReportingWidgetSortType.SchoolName : AdminBenchmarkReportingWidgetSortType.TeacherName,
    orderBy: OrderBy.Asc,
  };
  const initialQueryInputGrades = {
    sort: AdminBenchmarkReportingWidgetSortType.GradePosition,
    orderBy: OrderBy.Asc,
  };

  const getSortType = (tab: string) => {
    switch (tab) {
      case 'schools':
        return AdminBenchmarkReportingWidgetSortType.SchoolName;
      case 'teachers':
        return AdminBenchmarkReportingWidgetSortType.TeacherName;
      case 'grades': return AdminBenchmarkReportingWidgetSortType.GradePosition;
      default: return '';
    }
  };

  const [tab, setTab] = useState(0);

  const [queryInput, setQueryInput] = useState<{
    limit: number, page: number,
    sort: AdminBenchmarkReportingWidgetSortType | undefined, orderBy: OrderBy | undefined }>(initialQueryInput);
  const [queryInputGrades, setQueryInputGrades] = useState<{
    sort: AdminBenchmarkReportingWidgetSortType | undefined, orderBy: OrderBy | undefined }>(initialQueryInputGrades);

  useEffect(() => {
    pagination.setPage(1);
    setQueryInput({
      limit: rowsPerPage,
      page: 1,
      sort: queryInput.sort,
      orderBy: queryInput.orderBy,
    });
  }, [rowsPerPage]);

  const {
    data: typeformDetail,
    loading: typeformLoading,
    refetch: refetchAdminDashboardSurveyTypeform,
  } = useGetAdminDashboardSurveyTypeformPhase2Query();

  const [addTypeformUserMapping, { loading: updateUserLoading }]  = useAddTypeformUserMappingMutation();


  const { data: myDistrictData, loading: myDistrictDataLoading } = useMyDistrictQuery({
    fetchPolicy: 'network-only',
    skip: isSchoolAdmin,
  });

  const { data: mySchoolData, loading: mySchoolDataLoading } = useMySchoolQuery({
    fetchPolicy: 'network-only',
    skip: isDistrictAdmin,
  });

  const { data: lastSnowflakeUpdateTimeData, loading: getLastSnowflakeUpdateTimeLoading } = useGetLastSnowflakeUpdateTimeQuery({
    fetchPolicy: 'network-only',
  });

  const districtID = myDistrictData?.myDistrict?.id;
  const schoolID = mySchoolData?.mySchool?.id;

  const { data: grades, loading: gradeLoading } = useAdminGradeBenchmarkComparisonQuery({
    fetchPolicy: 'network-only',
    variables: {
      ...( isDistrictAdmin ? { districtId: districtID } : {}),
      ...( isSchoolAdmin ? { schoolId: schoolID } : {}),
      ...queryInputGrades,
    },
    skip: !(districtID || schoolID) && (myDistrictDataLoading || mySchoolDataLoading),
  });

  const { data: schools, loading: schoolLoading } = useAdminSchoolBenchmarkComparisonQuery({
    fetchPolicy: 'network-only',
    variables: {
      districtId: districtID as string,
      ...queryInput,
    },
    skip: isSchoolAdmin && myDistrictDataLoading || !districtID,
  });

  const { data: teachers, loading: teacherLoading } = useAdminTeacherBenchmarkComparisonQuery({
    fetchPolicy: 'network-only',
    variables: {
      schoolId: schoolID as string,
      ...queryInput,
    },
    skip: isDistrictAdmin && mySchoolDataLoading || !schoolID,
  });

  useEffect(() => {
    if (!gradeLoading || !teacherLoading || !schoolLoading)
      setComparisonTabs(prevState => {
        prevState.map((tabItem) => {
          switch (tabItem.value.toLowerCase()) {
            case 'grade':
              if (grades?.adminBenchmarkGradeComparison) {
                tabItem.progressScoreData = grades?.adminBenchmarkGradeComparison.nodes as any;
                tabItem.totalCount = grades?.adminBenchmarkGradeComparison.total_count as number | undefined;
              }
              break;
            case 'school':
              if (schools?.adminBenchmarkSchoolsComparison) {
                tabItem.progressScoreData = schools?.adminBenchmarkSchoolsComparison.nodes as any;
                tabItem.totalCount = schools?.adminBenchmarkSchoolsComparison.total_count as number | undefined;
              }
              break;
            case 'teacher':
              if (teachers?.adminBenchmarkTeacherComparison) {
                tabItem.progressScoreData = teachers?.adminBenchmarkTeacherComparison.nodes as any;
                tabItem.totalCount = teachers?.adminBenchmarkTeacherComparison.total_count as number | undefined;
              }
              break;
          }
          return tabItem;
        });
        return [...prevState];
      });
  }, [gradeLoading, schoolLoading, teacherLoading]);



  const handleTabChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
    if (comparisonTabs[newValue].value === 'grades') {
      setQueryInputGrades(initialQueryInputGrades);
    } else {
      setQueryInput(initialQueryInput);
    }
    history.push(RoutingPaths.AdminBenchmarkComparison + comparisonTabs[newValue].path);
  };


  const handlePageChange = (
    _: React.ChangeEvent<unknown>,
    value: React.SetStateAction<number>,
  ) => {
    pagination.setPage(value as number);
    setQueryInput({
      limit: rowsPerPage,
      page: value as number,
      sort: queryInput.sort,
      orderBy: queryInput.orderBy,
    });
  };

  const onSortChange = (sortBy: AdminBenchmarkReportingWidgetSortType, orderBy: OrderBy) => {
    pagination.setPage(1);
    if (comparisonTabs[tab].value.toLowerCase() === 'grade') {
      setQueryInputGrades({
        sort: sortBy,
        orderBy: orderBy,
      });
    } else {
      setQueryInput({
        limit: rowsPerPage,
        page: 1,
        sort: sortBy,
        orderBy: orderBy,
      });
    }
  };


  const handleSort = (column: AdminBenchmarkReportingWidgetSortType) => () => {
    if (column === queryInput.sort) {
      onSortChange(column, queryInput.orderBy === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else if (column === queryInputGrades.sort){
      onSortChange(column, queryInputGrades.orderBy === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else {
      onSortChange(column, OrderBy.Asc);
    }
  };

  // call mutation to add typeform user mapping
  const onTypeformReady = async () => {
    setDismissTypeform(true);
    try {
      await addTypeformUserMapping({
        variables: {
          typeformId: typeformDetail?.getAdminDashboardSurveyTypeformPhase2.id ?? '',
          submitted: false,
        },
      });
    } catch (err) {
      console.log('Typeform user update error', err);
    }
  };

  const onTypeformSubmit = async () => {
    try {
      await addTypeformUserMapping({
        variables: {
          typeformId: typeformDetail?.getAdminDashboardSurveyTypeformPhase2.id ?? '',
          submitted: true,
        },
      });
      refetchAdminDashboardSurveyTypeform();
      setDismissTypeform(true);
    } catch (err) {
      console.log('Typeform user update error', err);
    }
  };

  return {
    loading: gradeLoading || teacherLoading || schoolLoading || getLastSnowflakeUpdateTimeLoading || typeformLoading || updateUserLoading,
    lastSnowflakeUpdateTimeData: lastSnowflakeUpdateTimeData?.getLastSnowflakeUpdateTime,
    districtID,
    schoolID,
    comparisonTabs,
    handleTabChange,
    tab,
    pagination,
    handlePageChange,
    handleSort,
    rowsPerPage,
    setRowsPerPage,
    queryInput: comparisonTabs[tab].value.toLowerCase() === 'grade' ? queryInputGrades : queryInput,
    getSortType,
    onTypeformReady,
    onTypeformSubmit,
    typeformData: typeformDetail?.getAdminDashboardSurveyTypeformPhase2 ?? null,
    dismissTypeform,
    showSubmissionCount,
    setShowSubmissionCount,
    showFlyover,
    setShowFlyover,
    openFlyOver,
    toggleFlyover,
    flyoverData,
  };
};

export default useAdminBenchmarkProgressHook;
