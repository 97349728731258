/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette } from '../../theme/palette';

const DownloadFileIcon = (props: any) => {
  let { fillColor } = props;
  fillColor = fillColor ? fillColor : palette.customBlue.primaryBlue;
  return (
    <SvgIcon width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 16L4 17C4 18.6569 5.34315 20 7 20L17 20C18.6569 20 20 18.6569 20 17L20 16M16 12L12 16M12 16L8 12M12 16L12 4"
        stroke={fillColor ?? palette.customBlue.primaryBlue}
        fill={fillColor ?? palette.customBlue.primaryBlue}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
export default DownloadFileIcon;

