import React, { useState } from 'react';
import {
  AdminBenchmarkReportingWidgetSortType, OrderBy,
  useGetTeacherBmScoresForSchoolSlideoutQuery,
  useGetGradeBmScoresForTeacherSlideoutQuery,
  useGetSchoolBmScoresForGradeSlideoutQuery,
} from '../../../../../generated/graphql';
import usePagination from '../../../../../utils/usePagination';
import { PAGE_SIZE } from '../../../../../variables/constant';

const useAdminBenchmarkSlideoutHook = ({ id, slideOut, tabName, schoolID }: any) => {
  const [isSlideoutOpen, setIsSlideoutOpen] = useState(false);
  const pagination = usePagination('AdminBMSlideOut');

  const getSortType = (slideType: string) => {
    switch (slideType) {
      case 'school': return AdminBenchmarkReportingWidgetSortType.SchoolName;
      case 'teacher': return AdminBenchmarkReportingWidgetSortType.TeacherName;
      case 'grade': return AdminBenchmarkReportingWidgetSortType.GradePosition;
      default: break;
    }
  };

  const initialQueryInput = {
    limit: PAGE_SIZE,
    page: pagination.page,
    sort: getSortType(slideOut.toLowerCase()),
    orderBy: OrderBy.Asc,
  };

  const [queryInput, setQueryInput] = useState(initialQueryInput);

  const handleSlideoutClose = () => {
    setIsSlideoutOpen(false);
  };


  const handlePageChange = (
    _: React.ChangeEvent<unknown>,
    value: React.SetStateAction<number>,
  ) => {
    pagination.setPage(value as number);
    setQueryInput(prevInput => ({
      ...prevInput,
      page: value as number,
    }));
  };

  const onSortChange = (sortBy: AdminBenchmarkReportingWidgetSortType, orderBy: OrderBy) => {
    pagination.setPage(1);
    setQueryInput(prevInput => ({
      ...prevInput,
      page: 1,
      sort: sortBy,
      orderBy,
    }));
  };

  const handleSort = (column: AdminBenchmarkReportingWidgetSortType) => () => {
    if (column === queryInput.sort) {
      onSortChange(column, queryInput.orderBy === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else {
      onSortChange(column, OrderBy.Asc);
    }
  };


  const { data: schoolTeachersList, loading: schoolSpecificLoading } = useGetTeacherBmScoresForSchoolSlideoutQuery({
    fetchPolicy: 'network-and-cache',
    variables: {
      schoolId: id,
      ...queryInput,
    },
    skip: tabName !== 'school',
  });

  const { data: teacherGradeList, loading: teacherSpecificLoading } = useGetGradeBmScoresForTeacherSlideoutQuery({
    fetchPolicy: 'network-and-cache',
    variables: {
      teacherId: id,
      schoolId: schoolID,
      sort: queryInput.sort,
      orderBy: queryInput.orderBy,
    },
    skip: tabName !== 'teacher',
  });

  const { data: gradeSchoolList, loading: gradeSpecificLoading } = useGetSchoolBmScoresForGradeSlideoutQuery({
    fetchPolicy: 'network-and-cache',
    variables: {
      gradeId: id,
      ...queryInput,
    },
    skip: tabName !== 'grade' && slideOut.toLowerCase() !== 'school',
  });

  const getSlideoutData = () => {
    const data = {
      total_count: 0,
      list: [],
    };
    switch (slideOut.toLowerCase()) {
      case 'teacher':
        data.total_count = schoolTeachersList?.adminBenchmarkSchoolsSlideOutComparison?.total_count;
        data.list = schoolTeachersList?.adminBenchmarkSchoolsSlideOutComparison?.nodes;
        break;
      case 'grade':
        data.total_count = teacherGradeList?.adminBenchmarkTeacherSlideOutComparison?.total_count;
        data.list = teacherGradeList?.adminBenchmarkTeacherSlideOutComparison?.nodes;
        break;
      case 'school':
        data.total_count = gradeSchoolList?.adminBenchmarkGradeSlideOut?.total_count;
        data.list = gradeSchoolList?.adminBenchmarkGradeSlideOut?.nodes;
        break;
      default:
        break;
    }
    return data;
  };

  return {
    loading: schoolSpecificLoading || teacherSpecificLoading || gradeSpecificLoading,
    isSlideoutOpen,
    handleSlideoutClose,
    pagination,
    totalCount: getSlideoutData().total_count,
    queryInput,
    slideoutData: getSlideoutData().list,
    handlePageChange,
    handleSort,
    getSortType,
  };
};

export default useAdminBenchmarkSlideoutHook;
