import React from 'react';
import {
  Box,
  FormControlLabel, styled,
  Switch,
} from '@mui/material';

interface SwitchProps {
  switchLabel: string,
  placement?: 'top' | 'bottom' | 'end' | 'start' | undefined,
  switchColor?: 'primary' | 'secondary' | 'default' | undefined,
  name?: string,
  value?: boolean,
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
  fontSize?: string | undefined,
}

const StyledFormControl = styled(FormControlLabel)(({ theme }) => ({
  color: theme.palette.fontColors.fontBlack,
  fontWeight: 800,
  lineHeight: '25px',
  letterSpacing: '0.1px',
}));

const SwitchToggle = ({
  switchLabel,
  placement = 'start',
  switchColor = 'primary',
  name,
  value = false,
  handleChange,
  fontSize = '16px',
}: SwitchProps) => {
  return (
    <Box sx={{ mr: '16px', fontSize: fontSize }}>
      <StyledFormControl
        control={<Switch name={name} color={switchColor} onChange={handleChange} checked={value} />}
        label={switchLabel}
        labelPlacement={placement}
      />
    </Box>
  );
};

export default SwitchToggle;
